html,
body {
  height: 100%;
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 0;
}

h1 {
  font-style: italic;
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ff9c38;
  height: 100px;

  a {
    padding: 0 10px;
  }
}

.root-container {
  width: 100%;
  height: 100%;
}

.ghost {
  height: 15%;
  animation: float 2s ease-out infinite;
}

@keyframes float {
  50% {
    transform: translate(0, 30px);
  }
}

.ghost-container {
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  justify-content: center;
  align-items: center;
}
